@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

//Correct issue with logout icon display to close to text
.glyphicon-log-out {
  margin-right: 6px;
}
//Correct issues with delete button not displaying inline on Reports view
.inline-form {
  display: inline;
}
//Correct issue with navbar
.navbar-brand {
  padding:0;
}
.navbar {
  padding:10px;
}
//Application Buttons
.blue {
  color:#337ab7;
}
//Fix issue with button showing an additional line when grouped with inline forms
.decor-none {
  text-decoration: none!important;
}
//Make buttons smaller, bu still readable
.btn {
  font-size: 12px;
}