//.pincodes {
//  font-size: 14px;
//  padding: 0;
//}
//.img-logo {
//  height: 50px;
//}
//.img-photo {
//  height: 100px;
//  float: right;
//}
//PINCODE TEST
.pdf {
  margin: 0 auto;
  width: 1000px;
  padding: 0;
  display: block;
}
.pdf_page {
  display: table;
  page-break-after: always;
  page-break-inside: avoid;
  width: 100%;
}
.perforation {
  margin: 0;
  border-top: 1px dashed #333;
  border-bottom: 1px dashed #333;
  width: 50%;
  float: left;
}
.perforation:nth-child(odd) {
  border-right: 1px dashed #333;
}
.pincode-header {
  position: relative;
  font-size: 18px;
}
.img-logo {
  height: 50px;
  position: absolute;
  top: 0;
  left: 0;
}
.img-clientLogo {
  height: 50px;
  position: absolute;
  top: 0;
  right: 0;
}
.img-photoQR {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 125px;
}
.pincode-container {
  padding: 20px;
  height: 280px;
}
.store-name {
  margin-top: 25px;
  max-height: 60px;
  width: 100%;
  overflow: hidden;
}
.store-name span {
  line-height: 30px;
  overflow: hidden;
}
.pincode-footer {
  font-size: 20px;
  position: relative;
}